/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { getWindowSafe } from '@change-corgi/core/window';

declare global {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface Navigator {
		standalone: boolean | undefined;
	}
}

// eslint-disable-next-line complexity
export function isPWA(): boolean {
	const window = getWindowSafe();
	if (!window) return false;
	return !!(
		window.matchMedia?.('(display-mode: standalone)')?.matches ||
		window.matchMedia?.('(display-mode: fullscreen)')?.matches ||
		window.matchMedia?.('(display-mode: minimal-ui)')?.matches ||
		!!window.navigator?.standalone
	);
}
