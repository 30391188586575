import { Element } from 'domhandler';

import { ALLOWED_ATTR_CHARS, ALLOWED_ATTRS, YOUTUBE_REGEX } from '../../constants';
import type { AllowedAttr, RichMediaTagTransformer } from '../../types';
import { validateString } from '../../validateString';

export const encodeTransformer: RichMediaTagTransformer = () => {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	const additionalEncodeAttributes = { 'data-type': 'youtube' };

	const shouldTransformElement = (element: Element): boolean => {
		const matchedAttr = element.attributes.find((attribute) => attribute.name === 'src');
		try {
			return !!new URL(matchedAttr?.value ?? '').href.match(YOUTUBE_REGEX);
		} catch {
			return false;
		}
	};

	const addProtocolToIframeSrc = (element: Element) => {
		if (element.name === 'iframe') {
			const src: string = element.attribs.src || '';
			if (src) {
				const srcWithProtocol = src.startsWith('//') ? `https:${src}` : src;
				return new Element('iframe', { ...element.attribs, src: srcWithProtocol }, element.children);
			}
		}
		return element;
	};

	const transformTagFn = (el: Element): Element => {
		const element = addProtocolToIframeSrc(el);
		if (!shouldTransformElement(element)) return element;
		const newTagName = 'change-media';
		const newAttributes = {
			...additionalEncodeAttributes,
			...element.attributes.reduce<Record<string, string>>((newAttrs, attribute) => {
				const matchedName = ALLOWED_ATTRS.find((attrName) => attrName === attribute.name);
				const matchedValue =
					attribute.name === 'src' ? (attribute.value.match(YOUTUBE_REGEX)?.[1] ?? '') : attribute.value;
				if (matchedName && validateString(matchedValue, ALLOWED_ATTR_CHARS[attribute.name as AllowedAttr])) {
					return { ...newAttrs, [`data-${attribute.name}`]: matchedValue };
				}
				return newAttrs;
			}, {}),
		};
		return new Element(newTagName, newAttributes, element.children);
	};

	const transformer = { tagName: 'iframe', transformTagFn };

	return { transformer };
};
