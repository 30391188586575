/**
 * Identify Safari stack traces that don't contain the error message
 *
 * Safari:
 * ```
 * fn@file:1:2
 * fn2@file:1:2
 * ```
 * Chrome:
 * ```
 * Error: Oups
 *   at fn (file:1:2)
 *   at fn2 (file:1:2)
 * ```
 */
export const isSafariStack =
	// when not in a browser environment, we can't be in Safari
	typeof window === 'undefined'
		? () => false
		: (err: Error): boolean => {
				if (!err.stack) return false;
				if (!err.stack.includes('@')) return false;
				// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				const lines = err.stack.split('\n') || [];
				if (lines.length === 1) {
					return lines[0].includes('@') && !lines[0].includes(':');
				}
				return lines[1].includes('@');
			};
