/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useContext, useMemo, useState } from 'react';
import type { JSX, PropsWithChildren } from 'react';

import type { EmptyIntersection } from '@change-corgi/core/types';

import type { StateMutations, StateOptions, StateOptionsAdvanced, StateResult, StateResultMutations } from './types';

export function createStateContext<
	STATE extends EmptyIntersection,
	MUTATIONS extends StateMutations<STATE>,
	// TODO replace with EmptyObject from type-fest 3
	PROVIDER_PROPS extends Record<string, unknown> = { __value__: never },
>({
	name,
	mutations,
	initialState,
}: PROVIDER_PROPS extends { __value__: never }
	? StateOptions<STATE, MUTATIONS>
	: StateOptionsAdvanced<STATE, MUTATIONS, PROVIDER_PROPS>): StateResult<
	STATE,
	MUTATIONS,
	PROVIDER_PROPS extends { __value__: never } ? EmptyIntersection : PROVIDER_PROPS
> {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
	const StateContext = createContext<[STATE, StateResultMutations<MUTATIONS>]>(undefined as any);

	const StateProvider = function StateContextProvider({
		children,
		...props
	}: PropsWithChildren<PROVIDER_PROPS>): JSX.Element {
		const [state, setState] = useState<STATE>(
			typeof initialState === 'function' ? (initialState as (props: any) => STATE)(props) : initialState,
		);
		const derivedMutations = useMemo(() => {
			return Object.entries(mutations).reduce<Record<string, any>>((acc, [mutationName, fn]) => {
				// eslint-disable-next-line no-param-reassign, @typescript-eslint/no-unsafe-argument
				acc[mutationName] = (...args: any[]) => setState((currentState) => fn(currentState, ...args));
				return acc;
			}, {});
		}, [setState]) as StateResultMutations<MUTATIONS>;

		return <StateContext.Provider value={[state, derivedMutations]}>{children}</StateContext.Provider>;
	};
	StateProvider.displayName = `${name}Provider`;

	const useStateContext = function useStateContext() {
		const context = useContext(StateContext);

		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		if (context === null || context === undefined) {
			throw new Error(`${name} was not set`);
		}

		return context;
	};

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	return { StateContext, StateProvider: StateProvider as any, useStateContext };
}
