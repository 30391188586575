import type { TranslationKeyArg } from '../shared/types';

const SUPPORTED_SUFFIXES: readonly string[] = ['zero', 'one', 'two', 'few', 'many', 'other'];
const FALLBACK_SUFFIX = 'other';

// Temporary fix until OneSky is fixed
// see https://change.atlassian.net/browse/FE-583
const manyToOtherMapping = { many: 'other' };
/* eslint-disable @typescript-eslint/naming-convention */
const TEMPORARY_MAPPING: Partial<Record<string, Record<string, string>>> = {
	'fr-FR': manyToOtherMapping,
	'pt-BR': manyToOtherMapping,
	'pt-PT': manyToOtherMapping,
	'es-ES': manyToOtherMapping,
	'es-AR': manyToOtherMapping,
	'es-419': manyToOtherMapping,
	'it-IT': manyToOtherMapping,
};
/* eslint-enable @typescript-eslint/naming-convention */

// Intl.PluralRules.select seems to sometimes return "other: n" in some environments
function normalizeSuffix(locale: string, suffix: string) {
	if (TEMPORARY_MAPPING[locale]?.[suffix]) return TEMPORARY_MAPPING[locale][suffix];
	return SUPPORTED_SUFFIXES.includes(suffix) ? suffix : FALLBACK_SUFFIX;
}

export function getPluralKey(locale: string, key: TranslationKeyArg, count: number): string {
	const suffix = new Intl.PluralRules(locale).select(count);
	return `${key as string}.${normalizeSuffix(locale, suffix)}`;
}
