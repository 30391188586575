import type { Environment } from '@change-corgi/core/environment';
import type { Experiments } from '@change-corgi/core/experiments';
import { ensureOnline, isOnline } from '@change-corgi/core/network';
import { isPWA } from '@change-corgi/core/pwa';
import { getWindow } from '@change-corgi/core/window';

import { isLoggingEnabled, logEvents, toggleLogging } from './logging';
import type { Options as SendPayloadOptions } from './sendPayload';
import { sendPayload } from './sendPayload';
import type {
	EventTrackingDecorator,
	EventTrackingListener,
	TrackingEvent,
	TrackingEventProperties,
	TrackOptions,
} from './types';

const DEFAULT_SILENCED_ENVS: readonly Environment[] = ['development'];

type WebappInfo = Readonly<{
	/**
	 * name of app
	 */
	name?: string;
	buildTsUtc?: string;
	version?: string;
	versionNormalized?: string;
	commit?: string;
}>;

type SessionInfo = Readonly<{
	locale?: string;
	countryCode?: string;
	loginState?: 'AUTHENTICATED' | 'IDENTIFIED' | 'GUEST';
}>;

type Options = Readonly<{
	decorators?: readonly EventTrackingDecorator[];
	listeners?: readonly EventTrackingListener[];
	/**
	 * Currently necessary to detect if logging is enabled
	 */
	experimentOverrides?: Experiments['overrides'];
	environment: Environment;
	sessionInfo?: SessionInfo;
	webappInfo: WebappInfo;
	commonProperties?: Readonly<Record<string, unknown>>;
	/**
	 * Set for which environments errors should not be sent to Sentry
	 *
	 * default is `['development']`
	 */
	silencedEnvironments?: readonly Environment[];
	/**
	 * toggle whether logging is enabled if logging state is not yet persisted in local storage
	 *
	 * default logging is usually set to true only in dev environments
	 */
	defaultLoggingEnabled?: boolean;
}> &
	SendPayloadOptions;

/* eslint-disable @typescript-eslint/consistent-type-definitions, @typescript-eslint/method-signature-style */
interface EventTracker {
	readonly isLoggingEnabled: boolean;
	updateSessionInfo(sessionInfo: Partial<SessionInfo>): this;
	getCommonData(): Promise<Readonly<Record<string, unknown>>>;
	toggleLogging(enabled?: boolean): this;
	/**
	 * @example
	 * track<{ petition_id: string }>('supp_psf_comment_view', { petition_id: '42' });
	 * track(
	 *   { name: 'supp_psf_comment_view', { petition_id: '42' } } as TrackingEvent<{ petition_id: string }>,
	 *   { name: 'supp_psf_comment_post', { petition_id: '42', text } } as TrackingEvent<{ petition_id: string, text: string }>,
	 * );
	 */

	track(eventName: string, data?: TrackingEventProperties, options?: TrackOptions): Promise<boolean>;
	track(events: readonly TrackingEvent[]): Promise<boolean>;
	track(...events: readonly TrackingEvent[]): Promise<boolean>;
	/**
	 * add useful functions to the window object to enable/disable logging from the dev console for debugging
	 *
	 * @example
	 * window._trackLog.enabled
	 * window._trackLog.toggle()
	 * window._trackLog.toggle(true)
	 */
	decorateWindow(): this;
}
/* eslint-enable @typescript-eslint/consistent-type-definitions, @typescript-eslint/method-signature-style */

class EventTrackerImpl implements EventTracker {
	private readonly environment: Environment;
	private sessionInfo: SessionInfo;
	private readonly webappInfo: WebappInfo;
	private readonly silencedEnvironments: readonly Environment[];
	private commonData: Record<string, unknown> = {};
	private readonly additionalCommonData: TrackingEventProperties = {};
	private readonly decorators: Options['decorators'];
	private readonly listeners: Options['listeners'];
	private readonly experimentOverrides: Options['experimentOverrides'];
	private readonly sendPayloadOptions: SendPayloadOptions;
	private defaultLoggingEnabled = false;
	private initDone = false;

	constructor({
		decorators,
		listeners,
		experimentOverrides,
		httpMode,
		post,
		sendBeacon,
		getCsrfToken,
		environment,
		sessionInfo,
		webappInfo,
		commonProperties,
		silencedEnvironments,
		defaultLoggingEnabled,
	}: Options) {
		this.environment = environment;
		this.sessionInfo = sessionInfo || {};
		this.webappInfo = webappInfo;
		this.additionalCommonData = commonProperties || {};
		this.silencedEnvironments = silencedEnvironments || DEFAULT_SILENCED_ENVS;
		this.defaultLoggingEnabled = defaultLoggingEnabled || false;

		this.decorators = decorators;
		this.listeners = listeners;
		this.experimentOverrides = experimentOverrides;
		this.sendPayloadOptions = { httpMode, post, sendBeacon, getCsrfToken };
	}

	private initCommonData() {
		this.commonData = {
			...this.getSessionData(),
			...this.getWebappData(),
			// TODO add missing properties

			// see react-fe/lib/responsiveBreakpoints.js
			// responsive_breakpoint: breakpoint, // 'xs' | 'sm' | 'lg' | 'md'

			// fb_logged_in: loginStatusToTrackingStatusMap[facebookLoginStatus], // 'true' | 'false' | 'not_known'
			// google_logged_in: loginStatusToTrackingStatusMap[googleLoginStatus], // 'true' | 'false' | 'not_known'

			// // trackingData is populated directly from rendr-fe middleware
			// // and should contain the experiment data (at the time of server render)
			// // as well as session tracking data like UTM params
			// ...trackingData,

			// // Tracking data from the router
			// ...historyTrackingData,
		};
	}

	private init(): this {
		if (this.initDone) {
			return this;
		}

		this.initDone = true;
		this.initCommonData();
		return this;
	}

	updateSessionInfo(sessionInfo: Partial<SessionInfo>): this {
		this.sessionInfo = { ...this.sessionInfo, ...sessionInfo };
		this.initCommonData();
		return this;
	}

	private get isSilenced() {
		return this.silencedEnvironments.includes(this.environment);
	}

	get isLoggingEnabled(): boolean {
		return this.experimentOverrides
			? isLoggingEnabled(this.experimentOverrides, this.defaultLoggingEnabled)
			: this.defaultLoggingEnabled;
	}

	toggleLogging(enabled?: boolean): this {
		this.experimentOverrides &&
			toggleLogging(this.experimentOverrides, enabled === undefined ? !this.isLoggingEnabled : enabled);
		return this;
	}

	private logEvents(events: readonly TrackingEvent[], common: TrackingEventProperties) {
		if (!this.isLoggingEnabled) {
			return;
		}

		logEvents(events, common, this.isSilenced);
	}

	private getSessionData() {
		return {
			locale: this.sessionInfo.locale,
			country_code: this.sessionInfo.countryCode?.toUpperCase(),

			user_state: this.sessionInfo.loginState?.toLowerCase(),
		};
	}

	private getWebappData() {
		return {
			webapp_name: this.webappInfo.name,
			webapp_version: this.webappInfo.version,
			webapp_version_normalized: this.webappInfo.versionNormalized,
			webapp_build_ts_utc: this.webappInfo.buildTsUtc,
			client_sha: this.webappInfo.commit,
			pwa: isPWA(),
		};
	}

	async getCommonData() {
		const decoratorMaps = await Promise.all(
			(this.decorators || []).map(async (decorator) => {
				// handling rejected promises, just in case
				try {
					return await Promise.resolve(decorator.getEventTrackingData());
				} catch (e) {
					return {};
				}
			}),
		);

		const decoratorData = decoratorMaps.reduce((acc, props) => ({ ...acc, ...props }), {});
		return removeUndefined({
			...this.commonData,
			...this.additionalCommonData,
			...decoratorData,
			online: isOnline(),
		});
	}

	async track(eventName: string, data?: TrackingEventProperties, options?: TrackOptions): Promise<boolean>;
	async track(events: readonly TrackingEvent[]): Promise<boolean>;
	async track(...events: readonly TrackingEvent[]): Promise<boolean>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	async track(...args: any[]) {
		this.init();

		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		const events = normalizeTrackEvents(...args);

		if (events.length === 0) {
			return true;
		}

		const commonData = await this.getCommonData();

		this.logEvents(events, commonData);

		this.listeners && this.listeners.forEach((listener) => listener.onEventsTrack(events, commonData));

		if (this.isSilenced) {
			return true;
		}

		const payload = events.map((event) => ({
			label: event.name,
			data: {
				...commonData,
				...event.data,
				...event.defaultOverrides,
			},
		}));

		await ensureOnline({ waitTimeInMs: 0 });

		return sendPayload(payload.length === 1 ? payload[0] : payload, this.sendPayloadOptions);
	}

	decorateWindow(): this {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const eventTracker = this;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
		(getWindow() as any)._trackLog = {
			get enabled(): boolean {
				return eventTracker.isLoggingEnabled;
			},
			toggle: eventTracker.toggleLogging.bind(eventTracker),
		};

		// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
		(getWindow() as any).trackEvent = eventTracker.track.bind(this);

		return this;
	}
}

function removeUndefined(obj: Record<string, unknown>): Record<string, unknown> {
	return JSON.parse(JSON.stringify(obj)) as Record<string, unknown>;
}

function normalizeTrackEvents<DATA extends TrackingEventProperties>(
	eventName: string,
	data: DATA,
	options?: TrackOptions,
): readonly TrackingEvent[];
function normalizeTrackEvents(events: readonly TrackingEvent[]): readonly TrackingEvent[];
function normalizeTrackEvents(...events: readonly TrackingEvent[]): readonly TrackingEvent[];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function normalizeTrackEvents(...args: any[]) {
	if (typeof args[0] !== 'string') {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		return ([] as TrackingEvent[]).concat(...args);
	}

	const name = args[0];
	const data = (args[1] || {}) as TrackingEventProperties;
	const options = (args[2] || {}) as TrackOptions;

	return [{ name, data, ...options }];
}

export type { EventTracker };

export function createEventTracker(options: Options): EventTracker {
	return new EventTrackerImpl(options);
}

export function createEventTrackerFake(errorMsg: string): EventTracker {
	const errorFn = () => {
		throw new Error(errorMsg);
	};
	const errorAsyncFn = async () => {
		return Promise.reject(new Error(errorMsg));
	};
	return {
		get isLoggingEnabled() {
			return errorFn();
		},
		updateSessionInfo: errorFn,
		getCommonData: errorFn,
		toggleLogging: errorFn,
		decorateWindow: errorFn,
		track: errorAsyncFn,
	};
}
