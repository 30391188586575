import type Cookies from 'js-cookie';

import type { CsrfUtils } from '@change-corgi/core/csrf';
import type { EnvironmentUtils } from '@change-corgi/core/environment';
import type { ErrorReporter } from '@change-corgi/core/errorReporter/common';
import type { EventTracker } from '@change-corgi/core/eventTracker';
import type { Experiments } from '@change-corgi/core/experiments';
import type { Facebook } from '@change-corgi/core/facebook';
import type { FcmUtils } from '@change-corgi/core/fcm';
import type { GoogleAnalytics } from '@change-corgi/core/googleAnalytics';
import type { GoogleAuth } from '@change-corgi/core/googleAuth';
import type { GqlClient } from '@change-corgi/core/gql';
import type { HttpClient } from '@change-corgi/core/http';
import type { I18n } from '@change-corgi/core/i18n';
import type { Optimizely } from '@change-corgi/core/optimizely';
import type { NavigationUtils } from '@change-corgi/core/react/navigation';
import type { UserAgentUtils } from '@change-corgi/core/userAgent';

export type Utilities = Readonly<{
	environment: EnvironmentUtils;
	errorReporter: ErrorReporter;
	experiments: Experiments;
	facebook: Facebook;
	optimizely: Optimizely;
	googleAnalytics: GoogleAnalytics;
	/**
	 * @deprecated use `featureConfigs.xzy` GQL query instead
	 */
	fcm: FcmUtils;
	gql: GqlClient;
	googleAuth: GoogleAuth;
	http: HttpClient;
	tracker: EventTracker;
	navigation: NavigationUtils;
	userAgent: UserAgentUtils;
	csrf: CsrfUtils;
	cookies: Cookies.CookiesStatic;
	i18n: I18n;
	resolveUrl: (url: string) => string;
}>;

export type UtilityContext = Readonly<{
	environment: Readonly<{
		getEnvironment: EnvironmentUtils['getEnvironment'];
		getDemoEnvironment: EnvironmentUtils['getDemoEnvironment'];
		getApiEnvironment: EnvironmentUtils['getApiEnvironment'];
		getApiDemoEnvironment: EnvironmentUtils['getApiDemoEnvironment'];
		getBuildEnvironment: EnvironmentUtils['getBuildEnvironment'];
	}>;
	errorReporter: Readonly<{
		report: ErrorReporter['report'];
		updateSessionInfo: ErrorReporter['updateSessionInfo'];
		createSampledReporter: ErrorReporter['createSampledReporter'];
		wrap: ErrorReporter['wrap'];
		wrapFunction: ErrorReporter['wrapFunction'];
		wrapAsyncFunction: ErrorReporter['wrapAsyncFunction'];
		child: ErrorReporter['child'];
	}>;
	experiments: Readonly<{
		get: Experiments['get'];
		getAll: Experiments['getAll'];
		isServiceError: Experiments['isServiceError'];
	}>;
	facebook: Readonly<{
		ui: Facebook['ui'];
		load: Facebook['load'];
		login: Facebook['login'];
		authStatus: Facebook['authStatus'];
		authResponse: Facebook['authResponse'];
		isAuthenticated: Facebook['isAuthenticated'];
		isConnected: Facebook['isConnected'];
		sdkHash: Facebook['sdkHash'];
		locale: Facebook['locale'];
		apiVersion: Facebook['apiVersion'];
	}>;
	optimizely: Readonly<{
		getScript: Optimizely['getScript'];
		setState: Optimizely['setState'];
	}>;
	/**
	 * @deprecated use `featureConfigs.xzy` GQL query instead
	 */
	fcm: Readonly<{
		/**
		 * @deprecated use `featureConfigs.xzy` GQL query instead
		 */
		get: FcmUtils['get'];
		/**
		 * @deprecated use `featureConfigs.xzy` GQL query instead
		 */
		normalizeFcmValue: FcmUtils['normalizeFcmValue'];
	}>;
	gql: Readonly<{
		fetch: GqlClient['fetch'];
	}>;
	googleAuth: Readonly<{
		renderButton: GoogleAuth['renderButton'];
		prompt: GoogleAuth['prompt'];
		disabled: GoogleAuth['disabled'];
	}>;
	http: Readonly<{
		get: HttpClient['get'];
		head: HttpClient['head'];
		post: HttpClient['post'];
		put: HttpClient['put'];
		patch: HttpClient['patch'];
		delete: HttpClient['delete'];
		getAsset: HttpClient['getAsset'];
		sendBeacon: HttpClient['sendBeacon'];
	}>;
	tracker: Readonly<{
		/**
		 * @deprecated prefer using useTracking() hook from `@change-corgi/core/react/tracking`
		 */
		track: EventTracker['track'];
		updateSessionInfo: EventTracker['updateSessionInfo'];
		getCommonData: EventTracker['getCommonData'];
	}>;
	navigation: Readonly<{
		getExternalUrl: NavigationUtils['getExternalUrl'];
	}>;
	userAgent: Readonly<{
		getUserAgent: UserAgentUtils['getUserAgent'];
		getParsedUserAgent: UserAgentUtils['getParsedUserAgent'];
		getWebviewType: UserAgentUtils['getWebviewType'];
		isWebview: UserAgentUtils['isWebview'];
		isBot: UserAgentUtils['isBot'];
	}>;
	csrf: Readonly<{
		getCsrfToken: CsrfUtils['getCsrfToken'];
		refreshCsrfToken: CsrfUtils['refreshCsrfToken'];
	}>;
	cookies: Readonly<{
		get: (name: string) => string | undefined; // Cookies.CookiesStatic['get'];
		set: Cookies.CookiesStatic['set'];
		remove: Cookies.CookiesStatic['remove'];
	}>;
	i18n: Readonly<{
		translationExists: I18n['translationExists'];
		translate: I18n['translate'];
		translatePlural: I18n['translatePlural'];
		getCountries: I18n['getCountries'];
		getCountry: I18n['getCountry'];
		getLocale: I18n['getLocale'];
		localizeDate: I18n['localizeDate'];
		localizeRelativeTime: I18n['localizeRelativeTime'];
		localizeNumber: I18n['localizeNumber'];
		getDecimalSeparator: I18n['getDecimalSeparator'];
		localizeCurrency: I18n['localizeCurrency'];
		getCurrencySymbol: I18n['getCurrencySymbol'];
		getCurrencySymbolPosition: I18n['getCurrencySymbolPosition'];
		isZeroDecimalCurrency: I18n['isZeroDecimalCurrency'];
		amountToBaseUnits: I18n['amountToBaseUnits'];
		amountFromBaseUnits: I18n['amountFromBaseUnits'];
	}>;
	resolveUrl: Utilities['resolveUrl'];
}>;

type BoundFunctionsObject<O extends object, N extends keyof O> = Readonly<{
	[fn in N]: O[fn];
}>;

function createBoundFunctionsObject<O extends object, FN extends keyof O, GET extends keyof O = keyof O>(
	obj: O,
	fnList: readonly FN[],
	gettersList: readonly GET[] = [],
): BoundFunctionsObject<O, FN> {
	// in tests, utilities can be undefined
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	if (!obj) return obj;

	/* eslint-disable @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any */
	const res = fnList.reduce(
		(acc, name) => ({
			...acc,
			[name]: (obj[name] as any).bind(obj),
		}),
		{},
	) as any;
	return gettersList.reduce((acc, name) => {
		Object.defineProperty(acc, name, {
			get() {
				return obj[name];
			},
		});
		return acc;
	}, res);
	/* eslint-enable @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any */
}

export const ENVIRONMENT_FUNCTIONS: ReadonlyArray<keyof EnvironmentUtils> = [
	'getEnvironment',
	'getDemoEnvironment',
	'getApiEnvironment',
	'getApiDemoEnvironment',
	'getBuildEnvironment',
];
export const ERROR_REPORTER_FUNCTIONS: ReadonlyArray<keyof ErrorReporter> = [
	'report',
	'updateSessionInfo',
	'createSampledReporter',
	'wrap',
	'wrapFunction',
	'wrapAsyncFunction',
	'child',
];
export const EXPERIMENTS_FUNCTIONS: ReadonlyArray<keyof Experiments> = ['get', 'getAll', 'isServiceError'];
export const FACEBOOK_FUNCTIONS: ReadonlyArray<keyof Facebook> = ['ui', 'load', 'login'];
export const FACEBOOK_GETTERS: ReadonlyArray<keyof Facebook> = [
	'authStatus',
	'authResponse',
	'isAuthenticated',
	'isConnected',
	'sdkHash',
	'locale',
	'apiVersion',
];
export const FCM_FUNCTIONS: ReadonlyArray<keyof FcmUtils> = ['get', 'normalizeFcmValue'];
export const GQL_FUNCTIONS: ReadonlyArray<keyof GqlClient> = ['fetch'];
export const HTTP_FUNCTIONS: ReadonlyArray<keyof HttpClient> = [
	'get',
	'head',
	'post',
	'put',
	'patch',
	'delete',
	'getAsset',
	'sendBeacon',
];
export const TRACKER_FUNCTIONS: ReadonlyArray<keyof EventTracker> = ['track', 'updateSessionInfo', 'getCommonData'];
export const NAVIGATION_FUNCTIONS: ReadonlyArray<keyof NavigationUtils> = ['getExternalUrl'];
export const USER_AGENT_FUNCTIONS: ReadonlyArray<keyof UserAgentUtils> = [
	'getUserAgent',
	'getParsedUserAgent',
	'getWebviewType',
	'isBot',
];
export const USER_AGENT_GETTERS: ReadonlyArray<keyof UserAgentUtils> = ['isWebview'];
export const OPTIMIZELY_FUNCTIONS: ReadonlyArray<keyof Optimizely> = ['getScript', 'setState'];
export const CSRF_FUNCTIONS: ReadonlyArray<keyof CsrfUtils> = ['getCsrfToken', 'refreshCsrfToken'];
export const COOKIES_FUNCTIONS: ReadonlyArray<keyof Cookies.CookiesStatic> = ['get', 'set', 'remove'];
export const GOOGLE_AUTH_FUNCTIONS: ReadonlyArray<keyof GoogleAuth> = ['renderButton', 'prompt'];
export const GOOGLE_AUTH_GETTERS: ReadonlyArray<keyof GoogleAuth> = ['disabled'];
export const I18N_FUNCTIONS: ReadonlyArray<keyof I18n> = [
	'translationExists',
	'translate',
	'translatePlural',
	'getCountries',
	'getCountry',
	'getLocale',
	'localizeDate',
	'localizeRelativeTime',
	'localizeNumber',
	'getDecimalSeparator',
	'localizeCurrency',
	'getCurrencySymbol',
	'getCurrencySymbolPosition',
	'isZeroDecimalCurrency',
	'amountToBaseUnits',
	'amountFromBaseUnits',
];

export function createUtilityContextI18n(i18n: I18n): UtilityContext['i18n'] {
	return createBoundFunctionsObject(i18n, I18N_FUNCTIONS);
}

/**
 * this is used to cast Utilities into a more restricted type
 *
 * this also allows for destructuring with proper binding, which class would not
 *
 * @example
 * const { errorReporter: { report } } = utilityContext;
 */
export function createUtilityContext({
	environment,
	errorReporter,
	experiments,
	facebook,
	optimizely,
	googleAuth,
	fcm,
	gql,
	http,
	tracker,
	navigation,
	userAgent,
	csrf,
	i18n,
	cookies,
	resolveUrl,
}: Utilities): UtilityContext {
	return {
		environment: createBoundFunctionsObject(environment, ENVIRONMENT_FUNCTIONS),
		errorReporter: createBoundFunctionsObject(errorReporter, ERROR_REPORTER_FUNCTIONS),
		experiments: createBoundFunctionsObject(experiments, EXPERIMENTS_FUNCTIONS),
		facebook: createBoundFunctionsObject(facebook, FACEBOOK_FUNCTIONS, FACEBOOK_GETTERS),
		optimizely: createBoundFunctionsObject(optimizely, OPTIMIZELY_FUNCTIONS),
		fcm: createBoundFunctionsObject(fcm, FCM_FUNCTIONS),
		gql: createBoundFunctionsObject(gql, GQL_FUNCTIONS),
		http: createBoundFunctionsObject(http, HTTP_FUNCTIONS),
		tracker: createBoundFunctionsObject(tracker, TRACKER_FUNCTIONS),
		navigation: createBoundFunctionsObject(navigation, NAVIGATION_FUNCTIONS),
		userAgent: createBoundFunctionsObject(userAgent, USER_AGENT_FUNCTIONS, USER_AGENT_GETTERS),
		cookies: createBoundFunctionsObject(cookies, COOKIES_FUNCTIONS),
		googleAuth: createBoundFunctionsObject(googleAuth, GOOGLE_AUTH_FUNCTIONS, GOOGLE_AUTH_GETTERS),
		csrf: createBoundFunctionsObject(csrf, CSRF_FUNCTIONS),
		i18n: createUtilityContextI18n(i18n),
		resolveUrl,
	};
}
