import qs from 'qs';

import { addScript } from '@change-corgi/core/dom';
import type { ReportableError } from '@change-corgi/core/errorReporter/common';
import { getLocation, getWindow } from '@change-corgi/core/window';

declare global {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface Window {
		ga?: UniversalAnalytics.ga;
		ga_debug?: { trace: boolean };
	}
}

type Options = Readonly<{
	reportError: (error: ReportableError) => void;
	trackingId: string;
}>;

const SCRIPT_URL = 'https://www.google-analytics.com/analytics.js';
const SCRIPT_DEBUG_URL = 'https://www.google-analytics.com/analytics_debug.js';

function createFakeGa(): UniversalAnalytics.ga {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const res: UniversalAnalytics.ga = (() => {
		/* do nothing */
	}) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
	res.l = Number(new Date());
	res.q = [];
	return res;
}

/* eslint-disable @typescript-eslint/consistent-type-definitions, @typescript-eslint/method-signature-style */
interface GoogleAnalytics {
	/**
	 * enables/disables the availability of the feature (based on cookie prefs for instance)
	 *
	 * when enabling, this also initializes the feature
	 */
	toggle(enabled: boolean): this;
	readonly ga: UniversalAnalytics.ga;
}
/* eslint-enable @typescript-eslint/consistent-type-definitions, @typescript-eslint/method-signature-style */

class GoogleAnalyticsImpl implements GoogleAnalytics {
	private readonly trackingId: string;
	private readonly reportError: (error: ReportableError) => void;

	private readonly fakeGa: UniversalAnalytics.ga;

	private initDone = false;
	private enabled = false;

	constructor({ trackingId, reportError }: Options) {
		this.trackingId = trackingId;
		this.reportError = reportError;

		this.fakeGa = createFakeGa();
	}

	toggle(enabled: boolean): this {
		this.enabled = enabled;

		const window = getWindow();
		// disables if init was previously called
		// See https://developers.google.com/analytics/devguides/collection/analyticsjs/user-opt-out
		// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
		(window as any)[`ga-disable-${this.trackingId}`] = !this.enabled;

		this.init();

		return this;
	}

	/* eslint-disable max-lines-per-function */
	private init() {
		if (!this.enabled || this.initDone) {
			return;
		}

		this.initDone = true;

		const window = getWindow();

		(() => {
			window.ga =
				// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				window.ga ||
				// eslint-disable-next-line func-names, @typescript-eslint/no-explicit-any
				function (...args: any[]) {
					// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
					(window.ga.q = window.ga.q || []).push(args);
				};
			window.ga.l = Number(new Date());
		})();

		const { gaDebug } = qs.parse(getLocation().search.replace(/^\?/, ''));

		if (gaDebug) {
			window.ga_debug = { trace: true };
		}

		window.ga('create', this.trackingId, 'auto');
		window.ga('set', 'anonymizeIp', true);

		addScript(gaDebug ? SCRIPT_DEBUG_URL : SCRIPT_URL)
			// eslint-disable-next-line promise/prefer-await-to-then
			.catch((e) => {
				/* istanbul ignore next */
				(() => {
					// eslint-disable-next-line promise/catch-or-return
					fetch(SCRIPT_URL)
						// eslint-disable-next-line promise/prefer-await-to-then, promise/no-nesting, @typescript-eslint/prefer-promise-reject-errors
						.then(async (response) => (response.ok ? Promise.resolve() : Promise.reject(response)))
						// eslint-disable-next-line promise/prefer-await-to-then, promise/no-nesting
						.then(
							() => {
								this.reportError({
									error: new Error('Could not run Google Analytics script'),
								});
								return undefined;
							},
							(response) => {
								this.reportError({
									error: new Error('Could not retrieve Google Analytics script'),
									/* eslint-disable @typescript-eslint/no-unsafe-member-access */
									params: response.status
										? {
												message: response.statusText,
												status: response.status,
												url: SCRIPT_URL,
											}
										: {
												// eslint-disable-next-line @typescript-eslint/no-unsafe-call
												message: response.toString(),
												url: SCRIPT_URL,
											},
									/* eslint-enable @typescript-eslint/no-unsafe-member-access */
								});
							},
						);
				})();
				// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
				this.reportError(e);
			});
	}
	/* eslint-enable max-lines-per-function */

	get ga(): UniversalAnalytics.ga {
		return !this.initDone ? this.fakeGa : getWindow().ga;
	}
}

export type { GoogleAnalytics };

export function createGoogleAnalytics(options: Options): GoogleAnalytics {
	return new GoogleAnalyticsImpl(options);
}

export function createGoogleAnalyticsFake(errorMsg: string): GoogleAnalytics {
	const errorFn = () => {
		throw new Error(errorMsg);
	};
	return {
		get ga() {
			return errorFn();
		},
		toggle: errorFn,
	};
}
