import { getWindowSafe } from '@change-corgi/core/window';

export type WebviewType = 'facebook' | 'ios_standalone' | 'ios' | 'android' | 'unknown';

// inspired by https://github.com/atomantic/is-ua-webview
export function computeWebviewType(userAgent: string): WebviewType {
	if (!userAgent) {
		return 'unknown';
	}

	if (userAgent.includes('FBAN') || userAgent.includes('FBAV')) {
		return 'facebook';
	}

	if (/(iPhone|iPod|iPad)/i.test(userAgent)) {
		const window = getWindowSafe();
		// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
		if (typeof window !== 'undefined' && (window.navigator as any).standalone) {
			return 'ios_standalone';
		}

		if (!userAgent.includes('Safari')) {
			return 'ios';
		}
	}

	if (/Android.*(;\s+wv|Version\/\d.\d\s+Chrome\/\d+(\.0){3})/i.test(userAgent)) {
		return 'android';
	}

	return 'unknown';
}
