import {
	breadcrumbsIntegration,
	type BrowserOptions,
	browserProfilingIntegration,
	captureException,
	captureReactException,
	extraErrorDataIntegration,
	httpClientIntegration,
	init as initSentry,
	replayIntegration,
	thirdPartyErrorFilterIntegration,
} from '@sentry/react';

import type { SentryOptions, SentryScope } from '@change-corgi/core/errorReporter/common';

let sentryInitialized = false;

const HTTP_CATEGORIES = ['xhr', 'fetch', 'http'];

function genBeforeBreadcrumb(config: SentryOptions['config']): BrowserOptions['beforeBreadcrumb'] {
	const ignoredDomains = config.breadcrumbs?.ignoredDomains;
	if (!ignoredDomains) return undefined;
	return (breadcrumb) => {
		// https://develop.sentry.dev/sdk/event-payloads/breadcrumbs/#breadcrumb-types
		if (breadcrumb.category && HTTP_CATEGORIES.includes(breadcrumb.category) && breadcrumb.data?.url) {
			try {
				const { hostname } = new URL(breadcrumb.data.url as string, 'http://www.dummy.org');
				if (
					ignoredDomains.some((domain) => (typeof domain === 'string' ? domain === hostname : domain.test(hostname)))
				) {
					return null;
				}
			} catch (e) {
				// ignore
			}
		}
		return breadcrumb;
	};
}

// eslint-disable-next-line max-lines-per-function
export function createSentryScope({
	config,
	environment,
	silenced,
	options,
}: SentryOptions & { silenced?: boolean }): SentryScope {
	if (sentryInitialized) {
		throw new Error('Sentry has already been initialized');
	}

	sentryInitialized = true;

	initSentry({
		// https://docs.sentry.io/platforms/javascript/configuration/integrations/
		integrations: silenced
			? []
			: [
					breadcrumbsIntegration({
						console: true,
						dom: config.breadcrumbs?.domSerializedAttributes
							? {
									serializeAttribute: config.breadcrumbs.domSerializedAttributes,
								}
							: true,
						fetch: true,
						history: true,
						xhr: true,
					}),
					// browserTracingIntegration(),
					browserProfilingIntegration(),
					replayIntegration(config.htmlPiiSelectors),
					httpClientIntegration(),
					extraErrorDataIntegration({
						depth: 7,
					}),
					// for "development" environment, stacktraces might not include the right url
					...(config.applicationKey && environment !== 'development'
						? [
								thirdPartyErrorFilterIntegration({
									behaviour: config.reportThirdPartyErrors
										? 'apply-tag-if-exclusively-contains-third-party-frames'
										: 'drop-error-if-exclusively-contains-third-party-frames',
									filterKeys: [config.applicationKey],
								}),
							]
						: []),
				],
		beforeBreadcrumb: genBeforeBreadcrumb(config),
		ignoreErrors: [
			// to avoid sending too many hydration errors
			// we only keep "Text content does not match server-rendered HTML."
			/Hydration failed because/,
			/There was an error while hydrating/,
		],
		...(silenced
			? {
					profilesSampleRate: 0,
					// Session Replay
					replaysSessionSampleRate: 0,
					replaysOnErrorSampleRate: 0,
				}
			: {
					profilesSampleRate: 1.0,
					// Session Replay
					replaysSessionSampleRate: environment === 'development' ? 1.0 : 0.0001,
					replaysOnErrorSampleRate: environment === 'development' ? 1.0 : 0.001,
				}),
		...options,
	});

	return {
		captureException,
		captureReactException,
	};
}
