/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useState } from 'react';

import omit from 'lodash/fp/omit';

import type { EmptyIntersection } from '@change-corgi/core/types';

export type QueryHookResult<R extends Record<string, any>, E extends Record<string, any> = EmptyIntersection> =
	| { readonly status: 'loading' }
	| (Readonly<E> & { readonly status: 'error' })
	| (Readonly<R> & { readonly status: 'loaded' });

type QueryFn<R extends Record<string, any>> = () => Promise<R>;
type ErrorHandlerFn<E extends Record<string, any>> = (error: any) => E;
type Options<E extends Record<string, any> = Record<string, never>> = {
	readonly errorHandler?: ErrorHandlerFn<E>;
	/**
	 * whether to reset the state to "loading" in between queries
	 */
	readonly loadingBetweenQueries?: boolean;
};

export function useQuery<R extends Record<string, any>, E extends Record<string, any> = EmptyIntersection>(
	query: QueryFn<R>,
	deps: readonly any[],
	{ errorHandler, loadingBetweenQueries }: Options<E> = {},
): QueryHookResult<R, E> {
	const [result, setResult] = useState<QueryHookResult<R, E>>({ status: 'loading' });

	useEffect(() => {
		// do not set loadingBetweenQueries in deps
		let cancelled = false;
		loadingBetweenQueries && setResult({ status: 'loading' });
		(async () => {
			try {
				const queryResult = await query();
				// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				!cancelled && setResult({ status: 'loaded', ...queryResult });
			} catch (e) {
				const errorInfo: E = errorHandler ? errorHandler(e) : ({} as E);
				// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				!cancelled && setResult({ status: 'error', ...errorInfo });
			}
		})();
		return () => {
			cancelled = true;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);

	// so the loading object is always the same
	const loadingState = useMemo(() => ({ status: 'loading' as const }), []);
	return useMemo(() => {
		if (result.status === 'loading') {
			return loadingState;
		}
		return result;
	}, [loadingState, result]);
}

export type PrefetchedQueryState<R extends Record<string, any>, E extends Record<string, any> = EmptyIntersection> =
	| (Readonly<E> & { readonly status: 'error'; readonly __prefetchId: number | string })
	| (Readonly<R> & { readonly status: 'loaded'; readonly __prefetchId: number | string });
export type PrefetchableQueryHookResult<
	R extends Record<string, any>,
	E extends Record<string, any> = EmptyIntersection,
> = QueryHookResult<R, E>;
type PrefetchableOptions<
	R extends Record<string, any>,
	E extends Record<string, any> = EmptyIntersection,
> = Options<E> & {
	readonly id: number | string;
	readonly prefetchedState: PrefetchedQueryState<R, E> | undefined;
};

// polymorphism to avoid typing issues when using the hook without a errorHandler
function usePrefetchableQuery<R extends Record<string, any>>(
	query: QueryFn<R>,
	deps: readonly any[],
	{ prefetchedState, id }: Omit<PrefetchableOptions<R>, 'errorHandler'>,
): PrefetchableQueryHookResult<R>;
function usePrefetchableQuery<R extends Record<string, any>, E extends Record<string, any> = EmptyIntersection>(
	query: QueryFn<R>,
	deps: readonly any[],
	{ errorHandler, prefetchedState, id }: PrefetchableOptions<R, E>,
): PrefetchableQueryHookResult<R, E>;
function usePrefetchableQuery<R extends Record<string, any>, E extends Record<string, any> = EmptyIntersection>(
	query: QueryFn<R>,
	deps: readonly any[],
	{ errorHandler, prefetchedState, id, loadingBetweenQueries }: PrefetchableOptions<R, E>,
): PrefetchableQueryHookResult<R, E> {
	const [result, setResult] = useState<PrefetchableQueryHookResult<R, E>>(
		prefetchedState && prefetchedState.__prefetchId === id ? prefetchedState : { status: 'loading' },
	);

	useEffect(() => {
		if (result.status !== 'loading' && result.__prefetchId === id) {
			return undefined;
		}
		let cancelled = false;
		// do not set loadingBetweenQueries in deps
		loadingBetweenQueries && setResult({ status: 'loading' });
		(async () => {
			try {
				const queryResult = await query();
				// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				!cancelled && setResult({ status: 'loaded', ...queryResult });
			} catch (e) {
				const errorInfo: E = errorHandler ? errorHandler(e) : ({} as E);
				// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				!cancelled && setResult({ status: 'error', ...errorInfo });
			}
		})();
		return () => {
			cancelled = true;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);

	// so the loading object is always the same
	const loadingState = useMemo(() => ({ status: 'loading' as const }), []);
	return useMemo(() => {
		if (result.status === 'loading') {
			return loadingState;
		}
		return omit('__prefetchId', result) as PrefetchableQueryHookResult<R, E>;
	}, [loadingState, result]);
}

export { usePrefetchableQuery };

export async function prefetchQuery<R extends Record<string, any>, E extends Record<string, any> = EmptyIntersection>(
	query: QueryFn<R>,
	{ errorHandler, id }: Omit<PrefetchableOptions<R, E>, 'prefetchedState'>,
): Promise<PrefetchedQueryState<R, E>> {
	try {
		const queryResult = await query();
		return { status: 'loaded', __prefetchId: id, ...queryResult };
	} catch (e) {
		const errorInfo: E = errorHandler ? errorHandler(e) : ({} as E);
		return { status: 'error', __prefetchId: id, ...errorInfo };
	}
}
